/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/js/aa/contactAA/services/topicChangeDialogService.js
*    TO   --> ./legacy-web/src/main/assets/js/contact-aa/services/topic-change-dialog-service.js
**/

/* global contactModule:true */

angular.module('com.aa.contact').service('TopicChangeDialogService', function ($timeout) {
    var userLocale = AAcom.prototype.getProperty('user.locale');
    var topicChangeDialog = {
        showTopicChangeDialog: function (topicInfo) {
            showDialog(topicInfo);
        }
    };

    var showDialog = function (topicInfo) {
        var currentTopic = $j(contactModule.topicSelector).val();
        var fallBackTopic = $j('input[name="fallBackTopic"]').val();
        if (currentTopic != fallBackTopic) {
            contactModule.contactAA.util.aaDialog('changeTopic', {
                buttons: [
                    {
                        name: contactModule.changeTopicTxt,
                        cssClass: "btn",
                        callback: function () {
                            var topicChanged = $j('select[name="topicInfo.topic"]').find('option:selected').val();
                            var href = "/contact/forms?topic=" + topicChanged + "";
                            if (topicChanged == 'ACS') {
                                href = "/contactus/forms/?topic=" + topicChanged + "" + "&locale=" + userLocale;
                            }
                            window.location.href = href;
                        },
                        closeDialog: false
                    },
                    {
                        name: contactModule.keepTopicTxt,
                        cssClass: "btn btn-secondary",
                        callback: function () {
                            keepPreviousTopic(topicInfo);
                        },
                    }],
                onClose: function () {
                    keepPreviousTopic(topicInfo);
                },
                width: 'small',
                autoOpen: false,
                closeDialog: true,
                toggleScroll: true,
                closeOnEscape: false
            });
            contactModule.contactAA.util.aaDialog('changeTopic').openDialog();
            if ($device.mobile) {
                $j('#changeTopicDialogClose').hide();
            }
        }
    };

    var keepPreviousTopic = function (topicInfo) {
        $timeout(function () {
            var fallBackTopic = $j('input[name="fallBackTopic"]').val();
            topicInfo.topic = fallBackTopic;

            $j(contactModule.topicSelector).find('option')
                .filter(function () {
                    return $j(contactModule.topicSelector).val(fallBackTopic).prop('selected', true).focus();
                });
        }, 0);
    };

    return topicChangeDialog;
});
